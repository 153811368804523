document.addEventListener('DOMContentLoaded', (event) => {
  const productContentDetails = document.getElementsByClassName(
    'product-content-detail'
  );

  for (let productContent of productContentDetails) {
    const qtyInput = productContent.getElementsByClassName('qty')[0];
    if (qtyInput) {
      const parentDiv = qtyInput.parentNode;

      // Decrement Button
      const decBtn = document.createElement('button');
      decBtn.setAttribute('type', 'button');
      decBtn.setAttribute('class', 'qty-dec-btn');
      parentDiv.insertBefore(decBtn, qtyInput);

      // Increment Button
      const incBtn = document.createElement('button');
      incBtn.setAttribute('type', 'button');
      incBtn.setAttribute('class', 'qty-inc-btn');
      qtyInput.after(incBtn);

      decBtn.addEventListener('click', decQuantity);
      incBtn.addEventListener('click', incQuantity);

      function decQuantity(e) {
        e.preventDefault();

        const step = qtyInput.getAttribute('step');
        const result = step
          ? parseInt(qtyInput.value) - parseInt(step)
          : parseInt(qtyInput.value) - 1;
        qtyInput.value = result > 0 ? result : 1;
      }

      function incQuantity(e) {
        e.preventDefault();

        const step = qtyInput.getAttribute('step');
        const result = step
          ? parseInt(qtyInput.value) + parseInt(step)
          : parseInt(qtyInput.value) + 1;
        qtyInput.value = result;
      }
    }
  }
});
