import Swiper from "swiper/bundle";

const setWrapperStyles = (swiper) => {
  if (!swiper || !swiper.navigation.prevEl) {
    return;
  }
  var wrapperCenterClass = "swiper-wrapper--center";
  var buttonDisabledClass = swiper.params.navigation.disabledClass;
  var wrapperEl = swiper.wrapperEl;
  let prevBtn, nextBtn;

  if (Array.isArray(swiper.navigation.prevEl)) {
    prevBtn = swiper.navigation.prevEl[0];
    nextBtn = swiper.navigation.nextEl[0];
  } else {
    prevBtn = swiper.navigation.prevEl;
    nextBtn = swiper.navigation.nextEl;
  }
  if (
    (prevBtn.classList.contains(buttonDisabledClass) &&
      nextBtn.classList.contains(buttonDisabledClass)) ||
    swiper.isLocked
  ) {
    // center slides
    wrapperEl.classList.add(wrapperCenterClass);
  } else {
    wrapperEl.classList.remove(wrapperCenterClass);
  }
};

const buildSwiperWithNav = (sliderElm, index) => {
  sliderElm.classList.add(`testimonial-slider-${index}`);

  const nextButton = sliderElm.parentNode.querySelector(
    ".swiper-button-next"
  );
  const prevButton = sliderElm.parentNode.querySelector(
    ".swiper-button-prev"
  );
  nextButton.classList.add(`testimonial-slider-next-${index}`);
  prevButton.classList.add(`testimonial-slider-prev-${index}`);

  return new Swiper(`.testimonial-slider-${index}`, {
    direction:"horizontal",
    slidesPerView: 1,
    spaceBetween: 28,
    centeredSlides: false,
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
    navigation: {
      nextEl: `.testimonial-slider-next-${index}`,
      prevEl: `.testimonial-slider-prev-${index}`,
    },
    on: {
      init: function (swiper) {
        setTimeout(function () {
          setWrapperStyles(swiper);
        }, 200);
      },
      resize: setWrapperStyles,
    },
  });
};

document.addEventListener("DOMContentLoaded", function () {
  // Get all of the swipers on the page
  const testimonialsSliders = document.querySelectorAll(".testimonials .swiper");

  // Loop over all of the fetched sliders and apply Swiper on each one.
  testimonialsSliders.forEach((slider, i) => buildSwiperWithNav(slider, i));
});