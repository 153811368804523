function autocomplete(inp, arr) {
  var currentFocus;
  var clearSearch = document.querySelector('.find-a-clinic__clear-search');
  clearSearch.style.display = 'none';
  inp.addEventListener('input', function (e) {
    var a,
      b,
      i,
      val = this.value;
    /*close any already open lists of autocompleted values*/
    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    /*create a DIV element that will contain the items (values):*/
    a = document.createElement('DIV');
    a.setAttribute('id', this.id + ' autocomplete-list');
    a.setAttribute('class', ' autocomplete-items');
    /*append the DIV element as a child of the autocomplete container:*/
    /*Filter location on different tabs using a different  json array*/
    var json_value_ajax = document.getElementById('json-value-ajax');
    if (json_value_ajax) {
      var jsonDataAjax = json_value_ajax.getAttribute('data');
      arr = JSON.parse(jsonDataAjax);
    }
    this.parentNode.appendChild(a);
    let flag = false;
    for (i = 0; i < arr.length; i++) {
      /*check if the item starts with the same letters as the text field value:*/
      if (
        arr[i].address.substr(0, val.length).toUpperCase() ==
          val.toUpperCase() ||
        arr[i].post_code.substr(0, val.length) == val ||
        arr[i].country.substr(0, val.length).toUpperCase() ==
          val.toUpperCase() ||
        arr[i].state.substr(0, val.length).toUpperCase() == val.toUpperCase() ||
        arr[i].city.substr(0, val.length).toUpperCase() == val.toUpperCase() ||
        arr[i].state_short.substr(0, val.length).toUpperCase() ==
          val.toUpperCase()
      ) {
        /*create a DIV element for each matching element:*/
        b = document.createElement('DIV');
        b.classList.add('autocomplete-item');
        /*make the matching letters bold:*/
        // b.innerHTML = "<strong>" + arr[i].post_code.substr(0, val.length) + "</strong>";
        b.innerHTML = arr[i].address;
        b.innerHTML +=
          "<input type='hidden' data-pid='" +
          arr[i].place_id +
          "' value='" +
          arr[i].address +
          "'>";
        /*execute a function when someone clicks on the item value (DIV element):*/
        b.addEventListener('click', function (e) {
          inp.value = this.getElementsByTagName('input')[0].value;
          clearSearch.style.display = 'block';
          inp.setAttribute(
            'data-pid',
            this.getElementsByTagName('input')[0].getAttribute('data-pid')
          );
          closeAllLists();
          var form = document.querySelector('.aepr-map__search-form');
          e.preventDefault();
          var placeID = document
            .getElementById('aepr-map-search-input')
            .getAttribute('data-pid');
          var markers = document.querySelectorAll('.map-listing.marker');
          for (var i = 0; i < markers.length; i++) {
            var parentElement = markers[i].closest('.clinic-card');
            parentElement.style.display = 'none';
          }
          accordionItem = document.querySelectorAll(
            '.map-listing[data-pid="' + placeID + '"]'
          )[0];
          var aparentElement = accordionItem.closest('.clinic-card');
          aparentElement.style.display = 'block';
          accordionItem.click();
          clearSearch.addEventListener('click', function (e) {
            e.preventDefault();
            for (var i = 0; i < markers.length; i++) {
              var parentElement = markers[i].closest('.clinic-card');
              parentElement.style.display = 'block';
              inp.value = '';
              clearSearch.style.display = 'none';
            }
          });
        });
        flag = true;
        a.appendChild(b);
      }
    }
    if (a.childNodes.length === 0) {
      a.innerHTML = 'No clinics found for searched address.';
    }
  });

  /*execute a function presses a key on the keyboard:*/
  inp.addEventListener('keydown', function (e) {
    var x = document.getElementById(this.id + ' autocomplete-list');
    if (x) x = x.getElementsByTagName('div');
    if (e.keyCode == 40) {
      currentFocus++;
      addActive(x);
    } else if (e.keyCode == 38) {
      currentFocus--;
      addActive(x);
    } else if (e.keyCode == 13) {
      e.preventDefault();
      if (currentFocus > -1) {
        if (x) x[currentFocus].click();
      }
    }
  });
  function addActive(x) {
    if (!x) return false;
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = x.length - 1;
    x[currentFocus].classList.add('autocomplete-active');
  }
  function removeActive(x) {
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove('autocomplete-active');
    }
  }
  function closeAllLists(elmnt) {
    var x = document.getElementsByClassName('autocomplete-items');
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != inp) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }
  document.addEventListener('click', function (e) {
    closeAllLists(e.target);
  });
}
var json_value = document.getElementById('json-value');
var countries = '';
if (json_value) {
  var jsonData = json_value.getAttribute('data');
  countries = JSON.parse(jsonData);
}
var auto = document.getElementById('aepr-map-search-input');

if (auto) {
  autocomplete(auto, countries);
}

var searchBeaches = document.getElementById('search-beaches');
if (searchBeaches) {
  searchBeaches.addEventListener('click', function (e) {
    e.preventDefault();
    var placeID = document
        .getElementById('aepr-map-search-input')
        .getAttribute('data-pid'),
      accordionItem = document.querySelectorAll(
        '.map-listing.marker[data-pid="' + placeID + '"]'
      )[0];
    accordionItem.click();

    var resion = accordionItem.parentNode.parentNode.querySelectorAll('a')[0];
    if (resion.getAttribute('aria-expanded') != 'true') {
      resion.click();
    }
  });
}
