import Swiper from 'swiper/bundle';

const buildSwiperWithNav = (sliderElm, index) => {
  sliderElm.classList.add(`join-team-slider-${index}`);

  return new Swiper(`.join-team-slider-${index}`, {
    loop: true,
    slidesPerView: 1.5,
    spaceBetween: 24,
    speed: 5000,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
      stopOnLastSlide: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
      1440: {
        slidesPerView: 2.2,
        spaceBetween: 32,
      },
      1920: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
      2560: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  });
};

document.addEventListener('DOMContentLoaded', function () {
  // Get all of the swipers on the page
  const joinTeamSliders = document.querySelectorAll(
    '.join-team-title-slider.swiper'
  );
  // Loop over all of the fetched sliders and apply Swiper on each one.
  joinTeamSliders.forEach((slider, i) => buildSwiperWithNav(slider, i));
});
