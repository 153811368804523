$(document).ready(function() {
  if($('.pageSection').length){
    $('a[href*=#]').not('[href="#"]').not('[href="#0"]').bind('click', function(e) {
        e.preventDefault(); // prevent hard jump, the default behavior
        var target = $(this).attr("href"); // Set the target as variable

        // perform animated scrolling by getting top-position of target-element and set it as scroll target
        $('html, body').animate({
            scrollTop: $(target).offset().top + 100
        }, 4000, function() {
            location.hash = target; //attach the hash (#jumptarget) to the pageurl
        });

        return false;
    });
  }
    // on scroll  add active class to  active sidebar text
    $(window).scroll(function() {
      var scrollDistance = $(window).scrollTop();
      $(window).scroll(function() {
        var windscroll = $(window).scrollTop();
        if (windscroll >= 100) {
          // Assign active class to nav links while scolling
          $('.content-section').each(function(i) {
              if (($(this).position().top - 100 ) <= scrollDistance) {
                  $('#siderbar  ul li a.active').removeClass('active');
                  $('#siderbar ul li a').eq(i).addClass('active');
              }
          });
        }
      });
    }).scroll();
});