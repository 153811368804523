import '../dist/js/aepr-map';

(function ($) {
  'use strict';
  $(document).ready(function ($) {
    $(document).on('click', '.clinic_state_filter_tab', function (e) {
      e.preventDefault();
      $('.clinic_state_filter_tab.active').removeClass('active');
      $(this).addClass('active');
      let state = $(this).data('id');
      $('#filter-page').val(1);
      $('#filter-ids').val(state);
      $('#clinicStateFilter').trigger('submit');
    });

    $('#clinicStateFilter').on('submit', this, function (e) {
      e.preventDefault();
      $.ajax({
        url:
          aesthetic_prac_ajax_obj.ajax_url +
          '?action=filter_clinic_state&nonce=' +
          aesthetic_prac_ajax_obj.nonce,
        data: new FormData(this),
        xhrFields: {
          withCredentials: true,
        },
        type: 'POST',
        datatype: 'HTML',
        processData: false,
        contentType: false,
        beforeSend: function () {
          $('.stk-loader').css('display', 'flex');
          $('.clinic-list').html('');
        },
        success: function (response) {
          $('.clinic-list').html(response.data.html);
          deleteMarkers();
          markerCluster.clearMarkers();
          renderMap('init');
          handleLocationElementClick();
        },
      });
    });
  });
})(jQuery);
