import Swiper from 'swiper/bundle';

const setWrapperStyles = (swiper) => {
  if (!swiper || !swiper.navigation.prevEl) {
    return;
  }
  var wrapperCenterClass = 'swiper-wrapper--center';
  var buttonDisabledClass = swiper.params.navigation.disabledClass;
  var wrapperEl = swiper.wrapperEl;
  let prevBtn, nextBtn;

  if (Array.isArray(swiper.navigation.prevEl)) {
    prevBtn = swiper.navigation.prevEl[0];
    nextBtn = swiper.navigation.nextEl[0];
  } else {
    prevBtn = swiper.navigation.prevEl;
    nextBtn = swiper.navigation.nextEl;
  }
  if (
    (prevBtn.classList.contains(buttonDisabledClass) &&
      nextBtn.classList.contains(buttonDisabledClass)) ||
    swiper.isLocked
  ) {
    // center slides
    wrapperEl.classList.add(wrapperCenterClass);
  } else {
    wrapperEl.classList.remove(wrapperCenterClass);
  }
};

const buildSwiperWithNav = (sliderElm, index) => {
  sliderElm.classList.add(`crew-slider-${index}`);

  const nextButton = sliderElm.parentNode.querySelector(
    '.crew-swiper-button-next'
  );
  const prevButton = sliderElm.parentNode.querySelector(
    '.crew-swiper-button-prev'
  );
  nextButton.classList.add(`crew-swiper-button-next-${index}`);
  prevButton.classList.add(`crew-swiper-button-prev-${index}`);

  return new Swiper(`.crew-slider-${index}`, {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    watchOverflow: true,
    slideToClickedSlide: true,
    grabCursor: true,
    breakpoints: {
      // when window width is >= 320px

      // when window width is >= 480px
      768: {
        slidesPerView: 2,
      },
      // when window width is >= 640px
      1024: {
        slidesPerView: 4,
      },
    },
    navigation: {
      nextEl: `.crew-swiper-button-next-${index}`,
      prevEl: `.crew-swiper-button-prev-${index}`,
    },
    on: {
      init: function (swiper) {
        setTimeout(function () {
          setWrapperStyles(swiper);
        }, 200);
      },
      resize: setWrapperStyles,
    },
  });
};

document.addEventListener('DOMContentLoaded', function () {
  // Get all of the swipers on the page
  const crewSliders = document.querySelectorAll('.crew-members-slider.swiper');

  // Loop over all of the fetched sliders and apply Swiper on each one.
  crewSliders.forEach((slider, i) => buildSwiperWithNav(slider, i));
});
