import 'slick-carousel';

const slider = document.querySelector('.values-list-slider');

if(slider){
  $(slider).slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    infinite: true,
    loop:true,
    touchThreshold: 5000,
    // responsive  settings
    responsive:[
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  });
}


