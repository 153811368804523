import Swiper from 'swiper/bundle';
// init Swiper for news event slider :
const newsEventSlider = document.querySelector('.news-events-slider');
if (newsEventSlider) {
  new Swiper(newsEventSlider, {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 500,
    watchSlidesProgress: true,
    // If we need navigation
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      1023: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });
}
