import Swiper from 'swiper/bundle';

const buildSwiperWithNav = (sliderElm, index) => {
  sliderElm.classList.add(`similar-products-slider-${index}`);

  const nextButton = sliderElm.parentNode.querySelector(
    '.similar-products-swiper-button-next'
  );
  const prevButton = sliderElm.parentNode.querySelector(
    '.similar-products-swiper-button-prev'
  );
  nextButton.classList.add(`similar-products-swiper-button-next-${index}`);
  prevButton.classList.add(`similar-products-swiper-button-prev-${index}`);

  return new Swiper(`.similar-products-slider-${index}`, {
    loop: false,
    slidesPerView: 1,
    watchOverflow: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
    navigation: {
      nextEl: `.similar-products-swiper-button-next-${index}`,
      prevEl: `.similar-products-swiper-button-prev-${index}`,
    },
  });
};

document.addEventListener('DOMContentLoaded', function () {
  // Get all of the swipers on the page
  const similarProductsSliders = document.querySelectorAll(
    '.similar-products-slider.swiper'
  );

  // Loop over all of the fetched sliders and apply Swiper on each one.
  similarProductsSliders.forEach((slider, i) => buildSwiperWithNav(slider, i));
});
