/**
 * File sidebar-nav.js.
 *
 * show dropdown on pages load after clicking on dropdown nav
 */
const dropElement = document.querySelector('.current-cat-parent');
const dropBtn = document.querySelector('.accordion-button');
const dropChild = document.querySelector('.children');
if (dropElement) {
  dropBtn.classList.remove('collapsed');
  dropChild.classList.add('show');
}
